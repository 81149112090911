// TODO: consider: allow binding to limit available classes on element, reduce class bloat
let mode                  = config.style.responsive.responsiveClassMode;
let responsiveClassPrefix = config.style.responsive.responsiveClassPrefix;
let classMap              = config.style.responsive.classMap;


let defaultEvent = 'click';
let defaultClass = 'active';
let defaultDuration = '250';

let parseDirectiveArg = (arg) => {
    if (typeof arg === 'string') {
        let split = arg.split(',');

        arg = {
            event: split[0]      || defaultEvent,
            class: split[1]      || defaultClass,
            duration: split[2]   || defaultDuration,
        };

    }

    if (typeof arg !== 'object') {
        arg = {};
    }

    return {
        event: arg.event       || defaultEvent,
        class: arg.class       || defaultClass,
        duration: arg.duration || defaultDuration,
    }
}

let getGenericHandler = function (el, params) {

    let handler = () => {
        el.classList.add(params.class);
    
        utilities.setClientTimeout( () => {
            el.classList.remove(params.class);
        }, params.duration);
    }

    el.tempClassOnHandler = handler;

    return handler;


};
// on mount, tell observer to watch, when unmounting - stop watching
export default {
    mounted(el, binding) {
        let arg = parseDirectiveArg(binding.value)
        el.addEventListener(arg.event, getGenericHandler(el, arg));
    },
    beforeUnmount(el, binding) {
        let arg = parseDirectiveArg(binding.value)
        el.removeEventListener(arg.event, el.tempClassOnHandler);
    },
};
