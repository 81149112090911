let sortables = {};
let index = 0;
let sortablejs = null;

let lazyLoadSortable = async () => {
    if ( ! sortablejs) {
        sortablejs = await import('sortablejs');
    }

    return true;
};

export default {
    mounted(el, binding) {
        lazyLoadSortable().then( () => {
                 try {
                     let options = typeof binding.value === 'object' ? binding.value : {};
                     sortables[index] = sortablejs.Sortable.create(el, options);
                     el.sortableId = index;
                     index++;
                 } catch(e) {
                 
                 }

        });
    },
    beforeUnmount(el, binding) {
        try {
            sortables[el.sortableId].destroy();
            delete(sortables[el.sortableId]);
        } catch (e) {
        
        }
        
    },
};
