
// usage:
// v-dropdown="{opened: isDropDownOpened}" (isDropDownOpened - reactive boolean)
// @dropdown:closed="isDropDownOpened = false" support window close - change the reactive var when closing. otherwise

import {nextTick} from 'vue';
let getDropDownObject = ($dropdown, $target, options = {}, symbol) => {
    let positionAndShowDropdown = async ($dropdown = $dropdown, $target = $target, options = options) => {
       // get references and calculate
        let defaultGap                   = config.style.dropdown.gap ? config.style.dropdown.gap : 10;
        let viewportOffset               = $target.getBoundingClientRect();
        let targetTop                    = viewportOffset.top;
        let targetHeight                 = $target.offsetHeight;
        let $targetParent                = $target.parentElement
        let targetParentHeight           = $targetParent.offsetHeight;
        let totalHeight                  = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        let targetSpaceFromBottom        = totalHeight - targetTop - targetHeight;
        let targetSpaceFromTop           = targetTop;
        let targetOffsetFromParentTop    = $target.offsetTop;
        let targetOffsetFromParentBottom = targetParentHeight - targetOffsetFromParentTop;
        let gap                          = (options.gap ? options.gap : defaultGap);
        
        let placeDropdownOnBottom = () => {
            $dropdown.style.position   = 'absolute';
            $dropdown.style.bottom     = 'auto';
            $dropdown.style.top        = (+targetOffsetFromParentTop + targetHeight + gap) + 'px';
            $dropdown.style['z-index'] = $dropdown.style['z-index'] || 1000;
        };
        
        let placeDropdownOnTop = () => {
            $dropdown.style.position   = 'absolute';
            $dropdown.style.top        = 'auto';
            $dropdown.style.bottom     = (+targetOffsetFromParentBottom + gap) + 'px';
            $dropdown.style['z-index'] = $dropdown.style['z-index'] || 1000;
        };

        // position dropdown
        targetSpaceFromBottom >= targetSpaceFromTop ?  placeDropdownOnBottom() : placeDropdownOnTop();
        
        
        $dropdown.dispatchEvent(new Event('dropdown:opening'));
        utilities.wait(1).then( () => { // without a delay there is an issue with window click event close mechanism, using nextTick() doesn't work
            $dropdown.style.display = 'block';
            $dropdown[dropdownSymbol].isOpened = true;
            $dropdown.dispatchEvent(new Event('dropdown:opened'));
        });
    };
    let hideDropdown            = async () => {
        if ( ! $dropdown[dropdownSymbol].isOpened) {
            return;
            
        }
        $dropdown.style.display = 'none';
        $dropdown.dispatchEvent(new Event('dropdown:closing'));
        utilities.wait(1).then( () => { // without a delay there is an issue with window click event close mechanism, using nextTick() doesn't work
            $dropdown[dropdownSymbol].isOpened = false;
            $dropdown.dispatchEvent(new Event('dropdown:closed'));
        });
      
        
      
    }
    let makeElementDropdownable = () => {
        // parent must be positioned
        if ($dropdown.parentElement.style.display === 'static') {
            $dropdown.parentElement.style.display = 'relative'
        }
    
        // element initial visibility and position need to be adjusted
        $dropdown.style.display  = 'none';
        $dropdown.style.position = 'absolute';
    
        // apply animations by condig
        let desiredAnimationClass = config.style.dropdown.animationClass;
       
        if ( ! options.hasOwnProperty('applyAnimations') || options.applyAnimations) {
            if (typeof desiredAnimationClass === 'string') {
                desiredAnimationClass = desiredAnimationClass.split(' ');
            }
    
            if (Array.isArray(desiredAnimationClass)) {
                desiredAnimationClass.forEach(className => {
                    $dropdown.classList.add(className);
                });
            }
    
        }
   
        $dropdown[symbol].isOpened = false;
    }
    
    
    let result =  {
        isOpened: false,
        positionAndShowDropdown, hideDropdown, makeElementDropdownable,
    }
    
    $dropdown[symbol] = result;
    
    return result;
}

let dropdownSymbol = Symbol('saffron-drop-down');

export default {
    updated(el, {value: directiveData}) {
        if (utilities.isSSR()) {
            return;
        }
        
        if (directiveData.opened) {
            let sibling = el.previousSibling ? el.previousSibling : el.nextSibling;
            if ( ! sibling) {
                utilities.debug('dropdown directive: sibling not found. There must be a sibling next to the element with v-dropdown. This is used to place the dropdown. Expect problems.', 2);
            }
            el[dropdownSymbol].positionAndShowDropdown(el, sibling, directiveData)
        } else {
            el[dropdownSymbol].hideDropdown(el);
        }
    },
    mounted(el, {value: directiveData}) {
      
        if (utilities.isSSR()) {
            return;
        }
     
        // save reference to element
        el[dropdownSymbol] = getDropDownObject(el, el.previousSibling, directiveData, dropdownSymbol);
        
        // init the dropdown
        el[dropdownSymbol].makeElementDropdownable(el);

        // listen to window click event to close it
        window.addEventListener('click', () => {
            if (el[dropdownSymbol].isOpened) {
                el[dropdownSymbol].hideDropdown();
            }
        });
    
    },
    beforeUnmount(el) {
        if (utilities.isSSR()) {
            return;
        }
    
        try {
            window.removeEventListener('click', el[dropdownSymbol].hideDropdown);
        } catch (e) {
        
        }
     
    },
};
