// usage:
// v-tooltip="'autoTranslatedText'"
// v-tooltip="{content: 'autoTranslatedText'}"
// v-tooltip="{content: 'neverTranslatedText', autoTranslate:false}"
// v-tooltip="{content: 'forceTranslatedText', autoTranslate:true}"
// v-tooltip="{content: 'controlMyOffset', offset: [0, 16]}" // offset in px, see popper docs
// v-tooltip="{content: 'controlMyOffset', theme: 'lead'}" // sets theme class
// v-tooltip="{content: {type: 'image', url: '', height: '', width: ''}} // default image
// v-tooltip.bottom="'tooltip that tries to show on bottom if possible'"
// todo: nice to have: events, force show (programatic show), more themes
import {nextTick} from 'vue';

let popper            = false;
let createPopper      = false;
let animationClassIn  = config.style.weUI.tooltip.animationClassIn;
let animationClassOut = config.style.weUI.tooltip.animationClassOut;
let extraClass        = config.style.weUI.tooltip.extraClass;
let lazyLoadPopper    = async () => {
    if (popper) {
        return popper;
    }
    
    popper       = await import('@popperjs/core');
    createPopper = popper.createPopper;
    return true;
};

let getTooltipElement = (el, content, options) => {
    let safeContent    = content.replace('<script>', '');
    let html           = `<div class="tooltip saffron-tooltip ${extraClass} tooltip--${options.theme}"  role="tooltip">
                            <div role="tooltip-content">${safeContent}</div>
                            <div role="tooltip-arrow"></div>
                          </div>`;
    let template       = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.firstChild;
}

let getImageElement = (src, options) => {
    let safeSrc   = src.replace('<script>', '');
    let alt = options.alt ? options.alt.replace('<script>', '') : '';
    let height = options.height ? options.height : 150;
    let width = options.width ? options.width : 150;
    let html           = `<img src="${safeSrc}"
                            alt="${alt}"
                            style="max-height: ${height}px; max-width: ${width}px; height: auto;width:auto;object-fit: contain;">`;
    let template       = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.firstChild.outerHTML;
}
let tooltipSymbol = Symbol('saffron-tooltip');

let insertAfter = (targetNode, newNode) => {
    targetNode.parentNode.insertBefore(newNode, targetNode.nextSibling);
};

let showTooltip = (popper, instant = false) => {
    let el = popper.state.elements.popper;
    
    el.classList.remove(animationClassOut);
    el.style.display = 'block';
    
    if ( ! instant) {
        el.classList.add(animationClassIn);
        el.classList.add('active');
    }
    
    popper.update();
}

let hideTooltip = (popper, instant = false) => {
    let el = popper.state.elements.popper;
    
    el.classList.remove(animationClassIn);
    el.classList.remove('active');
    if (instant) {
        el.style.display = 'none';
    } else {
        el.classList.add(animationClassOut);
    }
    popper.update();
    
    
}

let bindPopper = async (options) => {
    if (utilities.isSSR()) {
        return;
    }
    
    await lazyLoadPopper();
    
    let tooltipElement = getTooltipElement(options.el, options.content, options);
    
    // inject invisible tooltip element
    insertAfter(options.el, tooltipElement);
    
    // make it a popper and handlers
    let popper      = createPopper(options.el, tooltipElement, {
        placement: options.placement,
        modifiers: [
            {
                name   : 'offset',
                options: {
                    offset: options.offset
                },
            },
            {
                name   : 'arrow',
                options: {
                    element: '[role="tooltip-arrow"]',
                    padding: options.arrowPadding ? options.arrowPadding : 0
                },
                
            },
        ]
    });
    let showHandler = () => {showTooltip(popper);};
    let hideHandler = () => {hideTooltip(popper);};
    
    // hide the tooltip right away
    hideTooltip(popper, true);
    
    // handlers to show and hide the tooltip further
    options.el.addEventListener('mouseenter', showHandler);
    options.el.addEventListener('focus', showHandler);
    options.el.addEventListener('mouseleave', hideHandler);
    options.el.addEventListener('blur', hideHandler);
    
    // save references on element so that we can remove this later
    options.el[tooltipSymbol] = {
        popper        : popper,
        tooltipElement: tooltipElement,
        showHandler   : showHandler,
        hideHandler   : hideHandler,
        options       : options,
    }
}

let unbindPopper = (options) => {
    if (utilities.isSSR()) {
        return;
    }
    try {
        let tooltipObject = options.el[tooltipSymbol];
        let el            = options.el;
        el.removeEventListener('mouseenter', tooltipObject.showHandler);
        el.addEventListener('focus', tooltipObject.showHandler);
        el.addEventListener('mouseleave', tooltipObject.hideHandler);
        el.addEventListener('blur', tooltipObject.hideHandler);
    } catch (e) {
    
    }
  
    nextTick(() => {
        try {
            tooltipObject.tooltipElement.remove();
        } catch (e) {
        
        }
    });
    
    
}

let getSafeOptions = (el, instance, value, modifiers) => {
    let safeOptions = {
        el, instance, value, modifiers, shouldApply:true
    }
    
    if ( ! value) {
        return {...safeOptions, shouldApply: false}
    }
    
    let rawValueOptions;
    
    if (value && typeof value === 'object') {
        rawValueOptions = {...value};
    } else {
        rawValueOptions = {content: value}
    }
    
    if ( ! rawValueOptions.hasOwnProperty('autoTranslate')) {
        rawValueOptions.autoTranslate = instance.autoTranslate;
    }
    
    if ( ! rawValueOptions.hasOwnProperty('translationParams')) {
        rawValueOptions.translationParams = {};
    }
  
    // content
    // string handling: auto translate content, if required (instance has auto translate, or we are instructed to auto translate
    if (rawValueOptions.autoTranslate && typeof rawValueOptions.content === 'string') {
        try {
            rawValueOptions.content = instance.translate(rawValueOptions.content, rawValueOptions.translationParams, true)
        } catch (e) { // exception can happen during development and block hmr
        
        }
    }
    
    // image handling
    if (rawValueOptions.content && typeof rawValueOptions.content === 'object' && rawValueOptions.content.type === 'image') {
        rawValueOptions.content = getImageElement(rawValueOptions.content.src, rawValueOptions.content);
    }
    
    if ( ! rawValueOptions.placement) {
        rawValueOptions.placement = modifiers.bottom ? 'bottom' : 'top';
    }
  
    
    if ( ! rawValueOptions.offset) {
        rawValueOptions.offset = [0, 10];
    }
    
    if ( ! rawValueOptions.theme) {
        rawValueOptions.theme = 'default';
    }
    
    safeOptions = {...safeOptions, ...rawValueOptions}
    return safeOptions;
}

export default {
    updated(el, {instance, value, modifiers}) {
    
    },
    mounted(el, {instance, value, modifiers}) {
        if (utilities.isSSR()) {
            return;
        }
        
        let options = getSafeOptions(el, instance, value, modifiers);
        
        if ( options.shouldApply) {
            bindPopper(options);
        }
    },
    beforeUnmount(el, {instance, value, modifiers}) {
        if (utilities.isSSR()) {
            return;
        }
        
        let options = getSafeOptions(el, instance, value, modifiers);
        if ( options.shouldApply) {
            unbindPopper(options);
        }
    
       
    },
};
